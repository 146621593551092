<template>
    <div>
        <top title="卡号详情"></top>
        <div class="detail_box">
            <div class="price_detail" @click="show = !show">
                <div class="df">
                    <div class="atv"></div>
                    <div>基本信息</div>
                </div>
                <van-icon name="arrow" v-if="!show" />
                <van-icon name="arrow-down" v-else />
            </div>
            <div v-show="show">
                <Cellitem title="姓名" :data="info.name"  />
                <Cellitem title="手机号" :data="info.phone"  />
                <Cellitem title="承保公司" :data="info.insCompanyName"  />
                <Cellitem title="保单号" v-if="info && info.policyNumber" :data="formatterData(info.policyNumber)"  />
<!--                <Cellitem title="人员证型" data="城乡居民"  />-->
                <!-- <Cellitem title="人员证型" :data="info.policyNumber"  /> -->
<!--                <Cellitem title="证件类型" :data="info.cardType == 1?'身份证':'未知'"  />-->
<!--                <Cellitem title="证件号码" :data="info.idCard"  />-->
<!--                <Cellitem title="险种" :data="info.insType"  />-->
<!--                <Cellitem title="省市" :data="info.cityCode"  />-->
<!--                <Cellitem title="卡号" :data="info.cardNumber"  />-->
<!--                <Cellitem title="总额度" :data="`¥${info.totalAmount}`"  />-->
                <Cellitem title="额度截止日期" :data="info.endingDate"  />
            </div>
            <div class="sil"></div>
            <div class="price_detail" @click="edShow = !edShow">
                <div class="df">
                    <div class="atv"></div>
                    <div>额度信息</div>
                </div>
                <van-icon name="arrow" v-if="!edShow" />
                <van-icon name="arrow-down" v-else />
            </div>
            <div v-show="edShow">
                <div class="price_text">商保用药</div>
                    <Cellitem title="商保额度" :data="`¥${info.businessAmount}`"  />
                    <Cellitem title="商保药已使用额度" :data="`¥${info.businessDrugAmount}`"  />
                    <Cellitem title="商保药剩余额度" :data="`¥${info.residueBusinessAmount}`"  />
<!--                    <Cellitem title="全药品赔付比例 " :data="`${info.drugRatio}%`"  />-->
<!--                    <Cellitem title="商保甲类赔付比例" :data="`${info.adrugRatio}%`"  />-->
<!--                    <Cellitem title="商保乙类赔付比例" :data="`${info.bdrugRatio}%`"  />-->
<!--                    <Cellitem title="商保其他类赔付比例" :data="`${info.cdrugRatio}%`"  />-->
                <div class="special price_text">特效用药</div>
                    <Cellitem title="特殊用药额度" :data="`¥${info.rapidDrugAmount}`"  />
                    <Cellitem title="特殊用药已使用额度" :data="`¥${info.useRapidDrugAmount}`"  />
                    <Cellitem title="特殊用药剩余额度" :data="`¥${info.rapidDrugBalance}`"  />
<!--                    <Cellitem title="特殊用药赔付比例" :data="`${info.rapidDrugRatio}%`"  />-->
                </div>
            </div>
    </div>
</template>

<script>
import Cellitem from '@/components/cell.vue';
import {cardDetail} from '@/api/_api/card'
export default {
    components:{Cellitem},
    data(){
        return{
            show:true,
            edShow:true,
            sbShow:false,
            txShow:true,
            info:{}
        }
    },
    computed:{
        dict(){
            return this.$store.state.dict || [];
        },
        insTypes(){
            let arr = [];
            this.dict && this.dict.forEach(element=>{
                if(element.groupId == 'insType'){
                    arr.push({label:element.dictName,value:element.dictId})
                }
            })
            return arr;
        }
    },
    methods:{
        init(val){
            cardDetail({cardNumber:val}).then(res=>{
                if(res.code == 200){
                    this.info = res.data;
                    this.insTypes.forEach(v=>{
                        if(v.value == this.info.insType){
                            this.info.insType = v.label
                        }
                    })
                }
            })
        },
      formatterData (val) {
          return val.substring(0, 3) + '*****' + val.substring(val.length - 2, val.length)
      }
    },
    mounted(){
        let { id } = this.$route.query;
        this.init(id)
    }
}
</script>

<style lang="scss" scoped>
    .detail_box{
        margin-bottom: vw(24)  ;
        .title{
            display: flex;
            align-items: center;
            font-size: vw(32);
            color: #333;
            font-weight: 600;
            margin:  vw(28) vw(28);
            margin-bottom: vw(28);
        }
        .sil{
            width:100%;
            height: vw(16);
            background: #f9f9f9;
        }
        .price_detail{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: vw(28) vw(24) vw(16);
            font-weight: 600;
        }
        .special{
            margin:vw(28) vw(24) vw(4) !important ;
        }
        .atv{
            width: vw(4);
            height: vw(32);
            background: $indexcolor;
            margin-right: vw(14);
        }
        .df{
            display: flex;
            align-items: center;
        }
        .price_text{
            margin:0 vw(24) vw(4);
            color: #333;
            font-weight: 600;
            font-size: vw(30);
        }

    }
</style>
