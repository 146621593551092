<template>
    <div class="item">
        <div class="item_left">{{title}}</div>
        <div class="item_right">{{data}}</div>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:()=>''
        },
        data:{
            default:()=>''
        }
    }
}
</script>

<style scoped lang="scss">
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: vw(26) vw(28);
        font-size: vw(28);
        color: #333;
        border-bottom: 1px solid #f4f4f4;
        .item_left{
            flex-shrink: 0;
        }
        .item_right{
            max-width: vw(500);
        }
    }
</style>