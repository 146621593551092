import { _axiosapi } from '../_axios';

//卡片管理列表
export const getCardListS = (params) => {
    return _axiosapi({
        url: `/member/homepage/cardList`,
        method:"POST",
        data:{request:params},
    })
}
//拉起当前账户全部卡片
export const getAllCardList = (params) =>{
    return _axiosapi({
        url:'/member/homepage/allCardList',
        method:'POST',
        data:{request:params}
    })
}
//卡号列表
export const cardNumberList =(params) =>{
    return _axiosapi({
        url:'/member/homepage/cardNumberList',
        method:'POST',
        data:{request:params}
    })
}
//卡号详情
export const cardDetail = (params ) =>{
    return _axiosapi({
        url:'/member/homepage/cardNumberDetails',
        method:'POST',
        data:{request:params}
    })
}
//设置默认卡片
export const updateDefaultPayment = (params ) =>{
    return _axiosapi({
        url:'/member/homepage/updateDefaultPayment',
        method:'POST',
        data:{request:params}
    })
}